<template>
  <tr>
    <td class="align-middle">
      <div>{{ videoUrl | basename }}</div>
      <div class="smaller text-muted">{{ videoDate }}</div>
    </td>
    <td class="align-middle text-end">
      <IconButton
        @click="showVideoModal = true"
        :icon="'video'"
        :disabled="isDeleting"
        class="btn btn-sm btn-primary ms-2"
      ></IconButton>
      <IconButton
        @click="showConfirmModal = true"
        :icon="'trash-alt'"
        :disabled="isDeleting"
        :isLoading="isDeleting"
        class="btn btn-sm btn-danger ms-2"
      ></IconButton>
    </td>

    <portal to="modal">
      <transition name="modal">
        <ExerciseVideoModal
          @close="showVideoModal = false"
          v-model="showVideoModal"
          v-if="showVideoModal"
          :video="video"
          :index="-1"
        ></ExerciseVideoModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteVideo"
          @close="showConfirmModal = false"
          v-if="showConfirmModal"
          v-model="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>
  </tr>
</template>

<script>
import { httpPost } from '@/core/http';

export default {
  name: 'AdminStorageManagerItem',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    ExerciseVideoModal: () => import('@/components/modal/ExerciseVideoModal'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    videoDate: {
      type: String,
      required: true,
    },
  },
  computed: {
    video() {
      const { videoUrl } = this;
      return { videoUrl };
    },
  },
  methods: {
    async deleteVideo() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const { videoUrl } = this;
        const res = await httpPost('/admin/storage/delete', { videoUrl });
        this.$emit('delete', res.data);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
  },
  data() {
    return {
      isDeleting: false,
      showVideoModal: false,
      showConfirmModal: false,
    };
  },
};
</script>
